* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'Minecraft';
  color: white;
  font-size: 22px;
}

@font-face {
  font-family: 'Minecraft Big';
  src: url('./MinecraftBig.ttf');
}

@font-face {
  font-family: 'Minecraft';
  src: url('./MinecraftRegular.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Minecraft';
  src: url('./MinecraftBold.otf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Minecraft';
  src: url('./MinecraftItalic.otf');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Minecraft';
  src: url('./MinecraftBoldItalic.otf');
  font-weight: bold;
  font-style: italic;
}

