$max-phone-width: 480px;
$max-tablet-width: 768px;
$max-laptop-width: 1024px;
$max-desktop-width: 1200px;
$min-large-screen-width: 1201px;

@mixin phone
{
    @media (max-width: #{$max-phone-width})
    {
        @content;
    }
}

@mixin tablet
{
    @media (min-width: #{$max-phone-width + 1px}) and (max-width: #{$max-tablet-width})
    {
        @content;
    }
}

@mixin laptop
{
    @media (min-width: #{$max-tablet-width + 1px}) and (max-width: #{$max-laptop-width})
    {
        @content;
    }
}

@mixin desktop
{
    @media (min-width: #{$max-laptop-width + 1px}) and (max-width: #{$max-desktop-width})
    {
        @content;
    }
}

@mixin large-screen
{
    @media (min-width: #{$max-desktop-width + 1px}) and (max-width: #{$max-large-screen-width})
    {
        @content;
    }
}
