@use "./mediaQueries";

body
{
  background-color: rgb(73, 32, 7);

}

.title
{
  text-align: center;


  .main-title
  {
    font-family: 'Minecraft';
    font-weight: bold;
    
    color: rgb(231, 231, 91);
    margin-bottom: -20px;
    
    font-size: 110px;

    @include mediaQueries.phone
    {
      font-size: 40px;
      margin-bottom: -15px;
    }

    @include mediaQueries.tablet
    {
      font-size: 70px;
    }

    @include mediaQueries.laptop
    {
      font-size: 90px;
    }

    @include mediaQueries.desktop
    {
      font-size: 110px;
    }
  }

  .sub-title
  {
    font-family: 'Minecraft';
    font-weight: bold;
    
    color:red;

    text-shadow: 2px 0 #000, -2px 0 #000, 0 2px #000, 0 -2px #000,
             1px 1px #000, -1px -1px #000, 1px -1px #000, -1px 1px #000;

    font-size: 40px;

    @include mediaQueries.phone
    {
      font-size: 25px;
    }

    @include mediaQueries.tablet
    {
      font-size: 40px;
    }

    @include mediaQueries.laptop
    {
      font-size: 40px;
    }

    @include mediaQueries.desktop
    {
      font-size: 40px;
    }
  }
}

.sparklez
{
  text-align: center;
  margin: 0 auto;
  margin-top: 100px;
  margin-bottom: 20px;

  @include mediaQueries.phone
  {
    margin-top: 50px;
  }
}

.DC-logo
{
  display: block;
  margin: 10px auto;

  width: 50vw;
  height: auto;

  @include mediaQueries.phone
  {
    width: 90vw;
  }

  @include mediaQueries.tablet
  {
    width: 90vw;
  }

  @include mediaQueries.laptop
  {
    width: 90vw;
  }

  @include mediaQueries.desktop
  {
    width: 70vw;
  }
}

.subtext
{
  text-align: center;
  font-size: 18px;
  font-style: italic;
  margin-bottom: 50px;
}

.greeting
{
  width: 90%;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 50px;

  br
  {
    margin-bottom: 10px;
  }

  .my-mail
  {
    color: yellow;
  }

  .mail-note
  {
    color: gray;
  }

  .discord
  {
    color: yellowgreen;
  }
}

.dont-swear
{
  display: block;
  margin: 0 auto;
  margin-bottom: 50px;

  width: 90vw;
  height: auto;

  width: 40vw;
  height: auto;

  @include mediaQueries.phone
  {
    width: 90vw;
  }

  @include mediaQueries.tablet
  {
    width: 90vw;
  }

  @include mediaQueries.laptop
  {
    width: 90vw;
  }

  @include mediaQueries.desktop
  {
    width: 60vw;
  }
}

.discord-info
{
  width: 90%;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 50px;

  br
  {
    margin-bottom: 10px;
  }

  .discord-link
  {
    color: yellow;
  }
}

.minecraft-end-text
{
  width: 90%;
  margin: 0 auto;
  text-align: center;

  display: flex;
  flex-direction: column;
  gap: 10px;

  p:nth-child(odd)
  {
    color: greenyellow;
  }

  p:nth-child(even)
  {
    color: rgb(98, 226, 255);
  }

  .break-after
  {
    margin-bottom: 250px;
  }

  .margin-after
  {
    margin-bottom: 150px;
  }
}

.credits
{
  color:lightgray;
  text-align: center;
  margin-bottom: 50px;
}